import Icon from '@ant-design/icons';
import { lazy, ReactNode } from 'react';
import { TFuncKey } from 'react-i18next';
import { RouteConfig } from 'types/route';
import { ReactComponent as BuyerSvg } from './assets/buyer.svg';
import { ReactComponent as BuyerActiveSvg } from './assets/buyerActive.svg';
import { ReactComponent as HomeSvg } from './assets/home.svg';
import { ReactComponent as HomeActiveSvg } from './assets/homeActive.svg';
import { ReactComponent as PolicySvg } from './assets/policy.svg';
import { ReactComponent as PolicyActiveSvg } from './assets/policyActive.svg';
import { ReactComponent as ManagementSvg } from './assets/management.svg';
import { ReactComponent as ManagementActiveSvg } from './assets/managementActive.svg';

const Home = lazy(() => import('views/Home'));
const BuyerCategory = lazy(() => import('views/BuyerCategory'));
const BuyerCategoryEdit = lazy(() => import('views/BuyerCategory/Edit'));
const BuyerProperty = lazy(() => import('views/BuyerProperty'));
const Policy = lazy(() => import('views/Policy'));
const PolicyEdit = lazy(() => import('views/Policy/Edit'));
const Bill = lazy(() => import('views/Bill'));
const Template = lazy(() => import('views/Template'));
const TemplateEdit = lazy(() => import('views/Template/Edit'));

export interface MenuRouteConfig extends Omit<RouteConfig, 'children'> {
  children?: MenuRouteConfig[];
  menu?: {
    label: TFuncKey;
    icon?: ReactNode;
    activeIcon?: ReactNode;
  };
}

export const mainChildRoutes: MenuRouteConfig[] = [
  {
    path: 'home',
    element: <Home />,
    menu: {
      label: '首页',
      icon: <Icon component={HomeSvg} />,
      activeIcon: <Icon component={HomeActiveSvg} />,
    },
  },
  {
    path: 'buyer',
    redirect: 'category',
    menu: {
      label: '买家分层',
      icon: <Icon component={BuyerSvg} />,
      activeIcon: <Icon component={BuyerActiveSvg} />,
    },
    children: [
      {
        path: 'category',
        element: <BuyerCategory />,
        menu: {
          label: '买家人群',
        },
      },
      {
        path: 'category/edit',
        element: <BuyerCategoryEdit />,
      },
      {
        path: 'property',
        element: <BuyerProperty />,
        menu: {
          label: '买家属性',
        },
      },
    ],
  },
  {
    path: 'policy',
    element: <Policy />,
    menu: {
      label: '营销策略',
      icon: <Icon component={PolicySvg} />,
      activeIcon: <Icon component={PolicyActiveSvg} />,
    },
  },
  {
    path: 'policy/edit',
    element: <PolicyEdit />,
  },
  {
    path: 'management',
    redirect: true,
    menu: {
      label: '配置与管理',
      icon: <Icon component={ManagementSvg} />,
      activeIcon: <Icon component={ManagementActiveSvg} />,
    },
    children: [
      {
        path: 'template',
        element: <Template />,
        menu: {
          label: '话术模板管理',
        },
      },
      {
        path: 'template/edit',
        element: <TemplateEdit />,
      },
      {
        path: 'bill',
        element: <Bill />,
        menu: {
          label: '账单管理',
        },
      },
    ],
  },
];
