import cn from './cn';

const en: typeof cn = {
  officialBuyer: {
    property: {
      name: {
        平台店铺名称: 'Store name',
        最后付款时间: 'Last payment date',
        最后咨询时间: 'Last consultation date',
        最后下单时间: 'Last order date',
        未评价: 'To rate',
        中差评: 'Negative review',
        退款状态: 'Refund status',
        累计购买商品数量: 'Cumulative number of purchased items',
        Dropshipper: 'Dropshipper',
        订单金额: 'Order Amount',
        订单量: 'Order Quantity',
        订单状态: 'Order Status',
        Shopee高差评: 'Shopee Highly Negative Review',
        Shopee高退款: 'Shopee High Refund',
      },
      description: {
        已授权系统的平台店铺名称: 'The store name which has authorized to MMax',
        买家最近一次付款时间: "Buyer's last payment date",
        对话中最近一次买家消息时间: "Date of buyer's last message in the conversation",
        买家最近一次下单但未付款时间: "The date of the buyer's last order but not paid",
        近7天店铺已签收的订单中未评价的买家:
          'Buyers have historical orders in our store but have not commented',
        买家历史在本店打过中差评价: 'Buyers have given negative reviews to orders in our store',
        买家在本店铺全部退款订单的状态: 'Status of refund order',
        买家在店铺中历史购买的商品总数量:
          'The total number of items that buyers have historically purchased in the store',
        Shopee中被标记dropship的买家: 'Buyers tagged as dropshipper in Shopee',
        '买家在店铺中累计已成交订单的金额数（$）':
          'The accumulated amount of the completed orders in the store ($)',
        买家在店铺中累计已成交订单的数量: 'The accumulated number of completed orders in the store',
        '买家在店铺中全部未完成的订单状态（一个买家可能有多个订单状态）': `The status of all the buyer's unfinished orders in the store (a buyer may have multiple order statuses)`,
        '平台级差评率大于10%的买家':
          'Buyers with a negative review rate of more than 10% on the Shopee platform',
        '平台级退款率大于20%的买家':
          'Buyers with a refund rate of more than 10% on the Shopee platform',
      },
    },
    category: {
      name: {
        全部买家: 'All buyers',
        已购买商品买家: 'Paid buyers',
        咨询未下单买家: 'Consult unordered buyers',
        潜在批发商: 'Potential wholesaler',
        店铺中差评买家: 'Negative buyers',
      },
      description: {
        系统中全部买家: 'All buyers in the system',
        在店铺已产生付款订单的买家: 'Buyers who have placed payment orders at the store',
        在店铺中咨询过某商品但并未产生订单:
          'Buyers who have inquired about a product in the store but have not placed an order',
        店铺中历史购买商家数量大于30的买家:
          'Buyers who have historically purchased more than 30 merchants in the store',
        在本店铺有过中差评的买家: 'Buyers who have had negative reviews on this store',
      },
    },
  },
  concept: {
    WA买家昵称: 'WA Buyer nickname',
    Shopee优惠券码: 'Shopee voucher code',
  },
  tradeStatus: {
    unpaid: 'unpaid',
    wait_seller_send_goods: 'wait_seller_send_goods',
    in_cancel: 'in_cancel',
    wait_buyer_confirm_goods: 'wait_buyer_confirm_goods',
    cancelled: 'cancelled',
    to_return: 'to_return',
    completed: 'completed',
  },
};

export default en;
