import 'antd/dist/antd.variable.min.css';
import 'react-app-polyfill/stable';
import 'moment/locale/zh-cn';
import { injectGlobal } from '@emotion/css';
import '@emotion/styled';
import { ConfigProvider } from 'antd';
import { initI18n } from 'i18n';

initI18n();

ConfigProvider.config({
  theme: {
    primaryColor: '#3370FF',
  },
});

injectGlobal`
  body {
    margin: 0;
  }
  #root {
    height: 100vh;
    min-width: 1440px;
  }
  * {
    overscroll-behavior: auto;
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: hsla(0, 0%, 70.6%, 0.5);
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
  }
`;
