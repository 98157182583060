const cn = {
  region: {
    cn: '中国',
    ph: '菲律宾',
    th: '泰国',
    my: '马来西亚',
    br: '巴西',
    id: '印度尼西亚',
    vn: '越南',
    sg: '新加坡',
    mx: '墨西哥',
  },
};

export default cn;
