import cn from './cn';

const en: typeof cn = {
  region: {
    cn: 'China',
    ph: 'Philippines',
    th: 'Thailand',
    my: 'Malaysia',
    br: 'Brazil',
    id: 'Indonesia',
    vn: 'Vietnam',
    sg: 'Singapore',
    mx: 'Mexico',
  },
};

export default en;
