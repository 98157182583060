import { AxiosClient, Interceptors, unauthorize } from '@leyan/ddone';
import { notification } from 'antd';
import { AxiosResponse } from 'axios';

const interceptors: Interceptors = {
  response: [
    (response) => response,
    (error) => {
      if (AxiosClient.isAxiosError(error)) {
        if (error.response?.status === 401) {
          notification.error({
            duration: 0,
            message: '未授权的访问',
            description: '授权信息已失效，请尝试重新授权',
          });

          unauthorize('@EVENT_TOKEN_FORBIDDEN', 'Service response with 401 code.');
        }
      }

      throw error;
    },
  ],
};

export interface DofaResponse<T = unknown> {
  code: number;
  msg: string;
  data: T;
}

export function resolve<T>(promise: Promise<AxiosResponse<DofaResponse<T>>>) {
  return promise.then((response) => {
    return response.data.data;
  });
}

const leyanbotHost = process.env.REACT_APP_LEYANBOT_HOST;

const authHeader = process.env.REACT_APP_AUTH_HEADER as string;

export const noAuthClient = new AxiosClient({
  baseURL: `${leyanbotHost}/dofa`,
});

export const dofaClient = new AxiosClient(
  {
    baseURL: `${leyanbotHost}/dofa`,
  },
  interceptors,
);

const authClients = [dofaClient];

export function authorize(token: string) {
  authClients.forEach((client) => {
    client.patchConfig({
      headers: {
        ...client.getConfig().headers,
        [authHeader]: `Bearer ${token}`,
      },
    });
  });
}
