import translation from '../translation/cn';
import time from '../time/cn';
import business from '../business/cn';
import language from '../language/cn';
import region from '../region/cn';

const cn = {
  translation,
  time,
  business,
  language,
  region,
};

export default cn;
