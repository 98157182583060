import { createNamedContext } from '@leyan/ddone';
import { defaultSystemLanguage } from 'i18n';
import { effect } from 'i18n/systemLanguage';
import { Dispatch, ProviderProps, SetStateAction, useContext, useEffect, useState } from 'react';

export interface GlobalValue {
  systemLanguage: General.SystemLanguge;
  setSystemLanguage: Dispatch<SetStateAction<General.SystemLanguge>>;
}

export const GlobalContext = createNamedContext<GlobalValue | null>('GlobalContext', null);

export const GlobalProvider = (props: Omit<ProviderProps<GlobalValue>, 'value'>) => {
  const [systemLanguage, setSystemLanguage] =
    useState<General.SystemLanguge>(defaultSystemLanguage);

  useEffect(() => {
    effect(systemLanguage);
  }, [systemLanguage]);

  return (
    <GlobalContext.Provider
      value={{
        systemLanguage,
        setSystemLanguage,
      }}
      {...props}
    />
  );
};

export const useGlobalValue = () => {
  const globalValue = useContext(GlobalContext);

  if (globalValue === null) {
    throw new Error('useGlobalValue() should be used in a context of GlobalProvider component');
  }

  return globalValue;
};
