import i18n from 'i18next';
import zhCNLocale from 'antd/lib/locale/zh_CN';
import enUSLocale from 'antd/lib/locale/en_US';
import { Locale } from 'antd/lib/locale-provider';

export const systemLanguageCacheKey = '@veterinary/systemLanguage';

export const systemLanguageLabelMap: Record<General.SystemLanguge, string> = {
  'zh-CN': '中文简体',
  'en-US': 'English',
};

export const systemLanguageLocaleMap: Record<General.SystemLanguge, Locale> = {
  'zh-CN': zhCNLocale,
  'en-US': enUSLocale,
};

export const isSystemLangauge = (systemLanguage: any): systemLanguage is General.SystemLanguge => {
  return systemLanguageLabelMap[systemLanguage as General.SystemLanguge] !== undefined;
};

export const getDefaultSystemLanguage = (fallback: General.SystemLanguge = 'zh-CN') => {
  const cachedSystemLanguage = localStorage.getItem(systemLanguageCacheKey);

  if (isSystemLangauge(cachedSystemLanguage)) {
    return cachedSystemLanguage;
  }

  const navigatorLanguage = navigator.language;

  if (isSystemLangauge(navigatorLanguage)) {
    return navigatorLanguage;
  }

  return fallback;
};

export const effect = (systemLanguage: General.SystemLanguge) => {
  i18n.changeLanguage(systemLanguage);

  localStorage.setItem(systemLanguageCacheKey, systemLanguage);
};
