import { AppLoading, useAuthorization } from '@leyan/ddone';
import { ReactNode, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { Routes } from 'react-router-dom';
import {
  createRedirectRoute,
  createRoute,
  loginRoute,
  mainChildRoutes,
  mainRoute,
} from './routeConfig';

function AuthRoutes() {
  const { authorized, createRestoreToLocation, createRedirectToLocation } = useAuthorization();
  const { t } = useTranslation();
  const routesRender = (routes: ReactNode) => {
    return (
      <Suspense fallback={<AppLoading />}>
        <Routes>{routes}</Routes>
      </Suspense>
    );
  };

  if (authorized) {
    const redirectToLocation = createRestoreToLocation(
      `${mainRoute.path}${mainChildRoutes[0].path}`,
    );
    return routesRender([
      createRoute(
        {
          ...mainRoute,
          redirect: redirectToLocation,
        },
        t,
      ),
      createRedirectRoute('*', redirectToLocation),
    ]);
  }

  const redirectToLocation = createRedirectToLocation(loginRoute.path);

  return routesRender([createRoute(loginRoute, t), createRedirectRoute('*', redirectToLocation)]);
}

export default AuthRoutes;
