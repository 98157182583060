import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getDefaultSystemLanguage } from './systemLanguage';
import cn from './resources/cn';
import en from './resources/en';

export const resources = {
  'zh-CN': cn,
  'en-US': en,
};

export const defaultSystemLanguage = getDefaultSystemLanguage();

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    resources,
    lng: defaultSystemLanguage,
    fallbackLng: defaultSystemLanguage,
    ns: ['translation'],
    defaultNS: 'translation',
  });
};

export default i18n;
