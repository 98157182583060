const cn = {
  officialBuyer: {
    property: {
      name: {
        平台店铺名称: '平台店铺名称',
        最后付款时间: '最后付款时间',
        最后咨询时间: '最后咨询时间',
        最后下单时间: '最后下单时间',
        未评价: '未评价',
        中差评: '中差评',
        退款状态: '退款状态',
        累计购买商品数量: '累计购买商品数量',
        Dropshipper: 'Dropshipper',
        订单金额: '订单金额',
        订单量: '订单量',
        订单状态: '订单状态',
        Shopee高差评: 'Shopee高差评',
        Shopee高退款: 'Shopee高退款',
      },
      description: {
        已授权系统的平台店铺名称: '已授权系统的平台店铺名称',
        买家最近一次付款时间: '买家最近一次付款时间',
        对话中最近一次买家消息时间: '对话中最近一次买家消息时间',
        买家最近一次下单但未付款时间: '买家最近一次下单但未付款时间',
        近7天店铺已签收的订单中未评价的买家: '近7天店铺已签收的订单中未评价的买家',
        买家历史在本店打过中差评价: '买家历史在本店打过中差评价',
        买家在本店铺全部退款订单的状态: '买家在本店铺全部退款订单的状态',
        买家在店铺中历史购买的商品总数量: '买家在店铺中历史购买的商品总数量',
        Shopee中被标记dropship的买家: 'Shopee中被标记dropship的买家',
        '买家在店铺中累计已成交订单的金额数（$）': '买家在店铺中累计已成交订单的金额数（$）',
        买家在店铺中累计已成交订单的数量: '买家在店铺中累计已成交订单的数量',
        '买家在店铺中全部未完成的订单状态（一个买家可能有多个订单状态）':
          '买家在店铺中全部未完成的订单状态（一个买家可能有多个订单状态）',
        '平台级差评率大于10%的买家': '平台级差评率大于10%的买家',
        '平台级退款率大于20%的买家': '平台级退款率大于20%的买家',
      },
    },
    category: {
      name: {
        全部买家: '全部买家',
        已购买商品买家: '已购买商品买家',
        咨询未下单买家: '咨询未下单买家',
        潜在批发商: '潜在批发商',
        店铺中差评买家: '店铺中差评买家',
      },
      description: {
        系统中全部买家: '系统中全部买家',
        在店铺已产生付款订单的买家: '在店铺已产生付款订单的买家',
        在店铺中咨询过某商品但并未产生订单: '在店铺中咨询过某商品但并未产生订单',
        店铺中历史购买商家数量大于30的买家: '店铺中历史购买商家数量大于30的买家',
        在本店铺有过中差评的买家: '在本店铺有过中差评的买家',
      },
    },
  },
  concept: {
    WA买家昵称: 'WA买家昵称',
    Shopee优惠券码: 'Shopee优惠券码',
  },
  tradeStatus: {
    unpaid: '待付款',
    wait_seller_send_goods: '待发货',
    in_cancel: '订单取消中',
    wait_buyer_confirm_goods: '待收货',
    cancelled: '已取消',
    to_return: '有退换',
    completed: '交易成功',
  },
};

export default cn;
