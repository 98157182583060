export const cn = {
  '本人已阅读并同意授权协议、隐私协议和服务协议':
    '本人已阅读并同意<x>授权协议</x>、<y>隐私协议</y>和<z>服务协议</z>',
  请先阅读并同意服务协议: '请先阅读并同意服务协议',
  Whatsapp会话费用说明: 'Whatsapp会话费用说明',
  客服: '客服',
  类别: '类别',
  市场营销: '市场营销',
  交易相关: '交易相关',
  身份验证: '身份验证',
};

export const en: typeof cn = {
  '本人已阅读并同意授权协议、隐私协议和服务协议':
    'I have read and accepted <x>Software License Agreement</x>, <y>Privacy Agreement</y> and <z>Service Agreement</z>',
  请先阅读并同意服务协议: 'Please read and agree to the Service Agreement first',
  Whatsapp会话费用说明: 'Description of Whatsapp expenditure',
  客服: 'Assistant',
  类别: 'Category',
  市场营销: 'Marketing',
  交易相关: 'Utility',
  身份验证: 'Authentication',
};
