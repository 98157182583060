import { TFunction } from 'i18next';
import { lazy } from 'react';
import { Navigate, Outlet, Route, To } from 'react-router-dom';
import { RouteConfig } from 'types/route';
import { mainChildRoutes } from 'views/Main/routeConfig';

const Login = lazy(() => import('views/Login'));
const Main = lazy(() => import('views/Main'));

export const loginRoute: RouteConfig = {
  path: '/login',
  element: <Login />,
};

export const mainRoute: RouteConfig = {
  path: '/',
  element: <Main />,
  redirect: true,
  children: mainChildRoutes,
};

export const createRedirectRoute = (path: string, to: To) => {
  return <Route key={`redirect_${path}`} path={path} element={<Navigate replace to={to} />} />;
};

const createRedirectRoutes = (to: To) => {
  return [createRedirectRoute('*', to), createRedirectRoute('', to)];
};

export const createRoute = (route: RouteConfig, t: TFunction) => {
  const { path, element, redirect, children = [] } = route;
  const childRoutes = children.map((childRoute) => {
    return createRoute(childRoute, t);
  });

  if (redirect === true) {
    const to = children[0].path;

    childRoutes.push(...createRedirectRoutes(to));
  } else if (redirect) {
    childRoutes.push(...createRedirectRoutes(redirect));
  }
  return (
    <Route key={path} path={path} element={element || <Outlet />}>
      {childRoutes}
    </Route>
  );
};

export { mainChildRoutes };
