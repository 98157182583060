import cn from './cn';
import translation from '../translation/en';
import time from '../time/en';
import business from '../business/en';
import language from '../language/en';
import region from '../region/en';

const en: typeof cn = {
  translation,
  time,
  business,
  language,
  region,
};

export default en;
