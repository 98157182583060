import { cn as newCn } from './new';

const cn = {
  首页: '首页',
  买家分层: '买家分层',
  买家人群: '买家人群',
  买家属性: '买家属性',
  营销策略: '营销策略',
  买家总数: '买家总数',
  'MMax可见范围内总买家数，包括已平台授权的店铺买家及导入的买家':
    'MMax可见范围内总买家数，包括已平台授权的店铺买家及导入的买家',
  导入记录: '导入记录',
  '导入中，请在导入记录中查看进度': '导入中，请在导入记录中查看进度',
  '导入失败，请稍后再试': '导入失败，请稍后再试',
  批量导入: '批量导入',
  '更多分析数据，敬请期待～': '更多分析数据，敬请期待～',
  消息发送总量: '消息发送总量',
  可触达买家总数: '可触达买家总数',
  活跃买家数: '活跃买家数',
  '获取x失败，请稍后再试': '获取{{x}}失败，请稍后再试',
  共x项: '共{{x}}项',
  下载链接: '下载链接',
  正在导入中: '正在导入中',
  '共x条买家记录，本次成功导入y个买家，z条导入异常。':
    '共{{x}}条买家记录，本次成功导入{{y}}个买家，{{z}}条导入异常。',
  下载导入异常记录: '下载导入异常记录',
  买家来源: '买家来源',
  '若您想导入平台买家，请导入已授权MMax的店铺买家，否则将导入失败。':
    '若您想导入平台买家，请导入已授权MMax的店铺买家，否则将导入失败。',
  平台买家: '平台买家',
  'WhatsApp 买家': 'WhatsApp 买家',
  请根据模版内容填写买家信息并在下方上传: '请根据模版内容填写买家信息并在下方上传',
  批量导入买家模版: '批量导入买家模版',
  '下载失败，请稍后再试': '下载失败，请稍后再试',
  请先选择买家来源: '请先选择买家来源',
  下载模版: '下载模版',
  上传文件: '上传文件',
  请上传文件: '请上传文件',
  点击或将文件拖拽到这里上传: '点击或将文件拖拽到这里上传',
  '支持文件格式：x': '支持文件格式：{{x}}',
  批量导入买家: '批量导入买家',
  确认导入: '确认导入',
  '导入前，买家属性须先在系统中创建。': '导入前，买家属性须先在系统中创建。',
  点击查看: '点击查看',
  x说明文档: '{{x}}说明文档',
  x列表: '{{x}}列表',
  输入x搜索: '输入{{x}}搜索',
  保存成功: '保存成功',
  '保存失败，请稍后再试': '保存失败，请稍后再试',
  '属性值已达上限，现不支持创建': '属性值已达上限，现不支持创建',
  新建x: '新建{{x}}',
  自定义属性: '自定义属性',
  请输入x: '请输入{{x}}',
  '请不要以“official”开头命名自定义买家属性': '请不要以“official”开头命名自定义买家属性',
  当前x已存在: '当前{{x}}已存在',
  属性名称: '属性名称',
  属性说明: '属性说明',
  '可设置难以穷尽的买家属性，例如. 爱好': '可设置难以穷尽的买家属性，例如. 爱好',
  '买家属性设置为数字类型后，可进行数字区间的筛选；例如年龄':
    '买家属性设置为数字类型后，可进行数字区间的筛选；例如年龄',
  '用于圈选不同指标的日期区间，比如筛选最近3天、2022.07.01～2022.07.07':
    '用于圈选不同指标的日期区间，比如筛选最近3天、2022.07.01～2022.07.07',
  '可设置易穷尽的买家属性，可在筛选客户时快速选择，例如买家等级':
    '可设置易穷尽的买家属性，可在筛选客户时快速选择，例如买家等级',
  属性值类型: '属性值类型',
  请选择x: '请选择{{x}}',
  '该类型的属性值已达x个上限，现不支持创建': '该类型的属性值已达{{x}}个上限，现不支持创建',
  属性值: '属性值',
  请添加x: '请添加{{x}}',
  完成: '完成',
  取消: '取消',
  添加: '添加',
  继续添加: '继续添加',
  编辑x: '编辑{{x}}',
  文本: '文本',
  数字: '数字',
  日期: '日期',
  枚举值: '枚举值',
  布尔值: '布尔值',
  限定商品: '限定商品',
  创建时间: '创建时间',
  操作: '操作',
  官方买家属性不可编辑: '官方买家属性不可编辑',
  编辑: '编辑',
  删除: '删除',
  删除成功: '删除成功',
  '删除失败，请稍后再试': '删除失败，请稍后再试',
  保存: '保存',
  官方: '官方',
  无买家属性规则设置: '无买家属性规则设置',
  可触达买家数: '可触达买家数',
  '营销策略“x”正在使用该人群，不可编辑': '营销策略“{{x}}”正在使用该人群，不可编辑',
  官方买家人群不可编辑: '官方买家人群不可编辑',
  官方买家人群不可删除: '官方买家人群不可删除',
  确定删除该买家人群吗: '确定删除该买家人群吗',
  '“x”正在使用该买家人群，请先将营销策略终止后再删除':
    '“{{x}}”正在使用该买家人群，请先将营销策略终止后再删除',
  我知道了: '我知道了',
  买家人群名称: '买家人群名称',
  买家人群说明: '买家人群说明',
  规则设置: '规则设置',
  '第一步：选择用户属性，设置属性值；': '<0>第一步：</0>选择用户属性，设置属性值；',
  '第二步：选择的属性规则展示在这里，确定规则后，点击保存即可新建人群成功':
    '<0>第二步：</0>选择的属性规则展示在这里，确定规则后，点击保存即可新建人群成功',
  买家属性规则: '买家属性规则',
  点击左侧标签加入过滤规则: '点击左侧标签加入过滤规则',
  选中规则: '选中规则',
  符合以上: '符合以上',
  请选择规则类型: '请选择规则类型',
  任一条件: '任一条件',
  全部条件: '全部条件',
  排除规则: '排除规则',
  '预计触达买家数：x': '预计触达买家数：<0>{{x}}</0>',
  买家数量: '买家数量',
  所属规则: '所属规则',
  限定条件: '限定条件',
  等于: '等于',
  大于等于: '大于等于',
  小于等于: '小于等于',
  介于: '介于',
  开始数字不可大于等于结束数字: '开始数字不可大于等于结束数字',
  请选择范围: '请选择范围',
  是: '是',
  否: '否',
  请选择天数: '请选择天数',
  日期区间: '日期区间',
  最近x天: '最近<0>{{x}}</0>天',
  x天前: '<0>{{x}}</0>天前',
  x天: '{{x}}天',
  下发数据: '下发数据',
  营销模版: '营销模版',
  '营销策略已创建，请点击按钮启用': '营销策略已创建，请点击按钮启用',
  立即创建: '立即创建',
  询单促转化: '询单促转化',
  复购周期唤醒: '复购周期唤醒',
  客户流失挽回: '客户流失挽回',
  热卖推荐: '热卖推荐',
  自定义营销: '自定义营销',
  x万: '{{x}}万',
  启用中: '启用中',
  未启用: '未启用',
  '结束时间不可小于当前时间，请检查营销时机': '结束时间不可小于当前时间，请检查营销时机',
  '关闭后营销策略将自动停止确认关闭吗？': '关闭后营销策略将自动停止确认关闭吗？',
  关闭策略: '关闭策略',
  点击按钮后开启营销策略: '点击按钮后开启营销策略',
  每天: '每天',
  每周days: '每周{{days}}',
  'x start～end 执行': '<0>{{x}}</0> <1>{{start}}～{{end}} 执行</1>', // 如：每周一、二、三 09:00～19:00 执行 | 每天 09:00～19:00 执行
  每月days: '每月{{days}}',
  总下发买家数: '总下发买家数',
  总下发已读数: '总下发已读数',
  总下发回复数: '总下发回复数',
  正在升级中: '正在升级中',
  查看数据: '查看数据',
  '日度下发买家数（近7天）': '日度下发买家数（近7天）',
  策略名称: '策略名称',
  营销渠道: '营销渠道',
  '敬请期待...': '敬请期待...',
  店铺: '店铺',
  指定人群: '指定人群',
  请先选择营销渠道: '请先选择营销渠道',
  选择人群: '选择人群',
  '已选人群：x': '已选人群：{{x}}',
  修改人群: '修改人群',
  需要选择一个人群: '需要选择一个人群',
  请搜索买家人群名称: '请搜索买家人群名称',
  '符合任一限定规则：': '符合任一限定规则：',
  '符合全部限定规则：': '符合全部限定规则：',
  '过滤以下规则：': '过滤以下规则：',
  营销时机: '营销时机',
  时区: '时区',
  东x区时间: '东{{x}}区时间',
  西x区时间: '西{{x}}区时间',
  指定时间: '指定时间',
  每周循环: '每周循环',
  每月循环: '每月循环',
  结束时间不可小于当前时间: '结束时间不可小于当前时间',
  请选择每周时间: '请选择每周时间',
  请选择每月时间: '请选择每月时间',
  每周x每日y: '<a>每周</a><x></x><b>每日</b><y></y>',
  每月x每日y: '<a>每月</a><x></x><b>每日</b><y></y>',
  营销内容: '营销内容',
  营销内容不可超出x个字: '营销内容不可超出{{x}}个字',
  存在未上传成功图片: '存在未上传成功图片',
  注意事项: '注意事项',
  最多上传x张图片: '最多上传 {{x}} 张图片',
  '下发效果示意（内容以实际配置为准）': '下发效果示意（内容以实际配置为准）',
  超出图片限制xM大小: '超出图片限制{{x}}M大小',
  上传图片: '上传图片',
  图片预览: '图片预览',
  重复营销设置: '重复营销设置',
  永不重复: '永不重复',
  '在本营销策略中，只对买家发送一次营销内容，不会对买家重复下发':
    '在本营销策略中，只对买家发送一次营销内容，不会对买家重复下发',
  重复营销过滤: '重复营销过滤',
  近x天被当前渠道已经营销过的买家: '<a>近</a><x></x><b>天被当前渠道已经营销过的买家</b>',
  营销设置: '营销设置',
  基本设置: '基本设置',
  启用中的策略不能编辑: '启用中的策略不能编辑',
  '买家人群已不存在，请重新选择': '买家人群已不存在，请重新选择',
  登录: '登录',
  欢迎登录MMax: '欢迎登录MMax',
  请通过乐聊超级管理员账号登录MMax: '请通过乐聊超级管理员账号登录MMax',
  '手机号/邮箱/账号': '手机号/邮箱/账号',
  密码: '密码',
  忘记密码: '忘记密码',
  登录中: '登录中',
  登录成功: '登录成功',
  '账号错误，请通过乐聊超级管理员账号登录': '账号错误，请通过乐聊超级管理员账号登录',
  '账号未授权，请联系MMax工作人员': '账号未授权，请联系MMax工作人员',
  '密码错误，请重新输入': '密码错误，请重新输入',
  '服务器开小差了，请稍后再试': '服务器开小差了，请稍后再试',
  请输入账号: '请输入账号',
  请输入密码: '请输入密码',
  请用乐聊超级管理员账号登录: '请用乐聊超级管理员账号登录',
  '如忘记密码，请联系乐聊超级管理员': '如忘记密码，请联系乐聊超级管理员',
  编辑买家人群: '编辑买家人群',
  编辑营销策略: '编辑营销策略',
  下发转化率: '下发转化率',
  '付款买家数/下发买家数': '付款买家数/下发买家数',
  '买家属性值为是/否，例如买家是否转化': '买家属性值为是/否，例如买家是否转化',
  '导入失败，请上传x格式文件': '导入失败，请上传{{x}}格式文件',
  '创建该买家属性后，属性值类型将无法变更': '创建该买家属性后，属性值类型将无法变更',
  配置与管理: '配置与管理',
  话术模板管理: '话术模板管理',
  账单管理: '账单管理',
  消费点券: '消费点券',
  近7天消费点券: '近7天消费点券',
  总会话数: '总会话数',
  商家发起会话数: '商家发起会话数',
  买家发起会话数: '买家发起会话数',
  查看消费明细: '查看消费明细',
  买家手机号: '买家手机号',
  买家昵称: '买家昵称',
  消费时间: '消费时间',
  会话发起方: '会话发起方',
  商家: '商家',
  买家: '买家',
  话术模板名称: '话术模板名称',
  模板状态: '模板状态',
  待审核: '待审核',
  已审核: '已审核',
  未通过: '未通过',
  已禁用: '已禁用',
  语言: '语言',
  最后编辑时间: '最后编辑时间',
  变量名称: '变量名称',
  变量说明: '变量说明',
  昨天: '昨天',
  近x天: '近{{x}}天',
  会话消费明细: '会话消费明细',
  下发WABA: '下发WABA',
  已消耗点券数: '已消耗点券数',
  明细列表: '明细列表',
  当前账户点券余额: '当前账户点券余额',
  账单: '账单',
  WhatsApp会话消费记录: 'WhatsApp会话消费记录',
  营销额度: '营销额度',
  设置营销额度: '设置营销额度',
  点券数: '点券数',
  '当前点券数较少，当消耗完后策略将停止营销': '当前点券数较少，当消耗完后策略将停止营销',
  '营销额度为营销策略最多消耗的点券数，额度为0时营销策略自动停止':
    '营销额度为营销策略最多消耗的点券数，额度为0时营销策略自动停止',
  手机号: '手机号',
  模板: '模板',
  话术模板: '话术模板',
  请上传话术中的x: '请上传话术中的{{x}}',
  uploadImageTips: '为保证浏览效果，请选择16:9横屏图片',
  '支持扩展名：x': '支持扩展名：{{x}}',
  请选择话术中的优惠券码: '请选择话术中的优惠券码',
  请先选择店铺: '请先选择店铺',
  请输入话术中的x: '请输入话术中的{{x}}',
  优惠券: '优惠券',
  按钮文本: '按钮文本',
  操作类型: '操作类型',
  拨打电话: '拨打电话',
  跳转链接: '跳转链接',
  '国家/地区': '国家/地区',
  电话号码: '电话号码',
  网址类型: '网址类型',
  静态: '静态',
  动态: '动态',
  网址: '网址',
  无: '无',
  快速回复: '快速回复',
  行动号召: '行动号召',
  最多设置x个按钮: '最多设置{{x}}个按钮',
  添加按钮: '添加按钮',
  按钮: '按钮',
  '创建按钮，以便买家回复您的消息或采取操作': '创建按钮，以便买家回复您的消息或采取操作',
  渠道: '渠道',
  正文: '正文',
  正文不可超出x个字: '正文不可超出x个字',
  请用您所选择的语言输入消息文本: '请用您所选择的语言输入消息文本',
  页脚: '页脚',
  '应WhatsAspp官方要求，首次触达买家需要获取opt-in,该信息在话术页脚固定展示，不可修改':
    '应WhatsAspp官方要求，首次触达买家需要获取opt-in,该信息在话术页脚固定展示，不可修改',
  素材: '素材',
  请选择素材类型: '请选择素材类型',
  请上传示例: '请上传示例',
  页眉: '页眉',
  添加页眉或为页眉选择要使用的素材类型: '添加页眉或为页眉选择要使用的素材类型',
  图片: '图片',
  视频: '视频',
  文件: '文件',
  支持x: '支持{{x}}',
  最大x: '最大{{x}}', // 如：最大20M
  上传示例: '上传示例',
  模板名称: '模板名称',
  '模板名称只能包含小写字母、数字和下划线': '模板名称只能包含小写字母、数字和下划线',
  示例内容: '示例内容',
  请输入示例内容: '请输入示例内容',
  变量: '变量',
  上传x: '上传{{x}}',
  pdf第一页缩略图: 'pdf第一页缩略图',
  文件名称: '文件名称',
  输入后话术预览展示在这里: '输入后话术预览展示在这里',
  预览: '预览',
  表情: '表情',
  加粗: '加粗',
  倾斜: '倾斜',
  删除线: '删除线',
  待审核的模板不可编辑: '待审核的模板不可编辑',
  已审核的话术模板每24小时仅可编辑一次: '已审核的话术模板每24小时仅可编辑一次',
  '“x”已成功提交': '“{{x}}”已成功提交',
  '确定要取消吗？': '确定要取消吗？',
  取消后该模板将不被保存: '取消后该模板将不被保存',
  继续填写: '继续填写',
  确认取消: '确认取消',
  选择渠道: '选择渠道',
  设置话术模板内容: '设置话术模板内容',
  templateTips:
    '消息模板将由WhatsApp官方审核，请提前了解<x>WhatsApp消息模板质量评分</x>，并遵守<y>WhatsApp Business政策</y>',
  下一步: '下一步',
  x不限: '{{x}}不限',
  状态: '状态',
  请输入网址中x的内容: '请输入网址中{{x}}的内容',
  '添加变量即可在使用模板时赋值不同的网址。您只能添加一个变量到网址结尾处。':
    '添加变量即可在使用模板时赋值不同的网址。您只能添加一个变量到网址结尾处。',
  详细了解: '详细了解',
  已有的语言: '已有的语言',
  '话术模板中已有的名称，可点击直接选择': '话术模板中已有的名称，可点击直接选择',
  '点击查看变量说明文档，请遵守WhatsApp Business政策':
    '<x>点击查看</x>变量说明文档，请遵守<y>WhatsApp Business政策</y>',
  此名称在该语言下已存在: '此名称在该语言下已存在',
  转化金额: '转化金额',
  ...newCn,
};

export default cn;
