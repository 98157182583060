const whatsApp: Record<Template.WhatsAppLanguage, string> = {
  zh_CN: '中文（中国大陆）',
  en: '英语',
  fil: '菲律宾语',
  id: '印尼语',
  ms: '马来语',
  pt_BR: '葡萄牙语（巴西）',
  es_MX: '西班牙语（墨西哥）',
  th: '泰语',
  vi: '越南语',
};

const cn = {
  whatsApp,
};

export default cn;
