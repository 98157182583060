import cn from './cn';
import { en as newEn } from './new';

const en: typeof cn = {
  首页: 'Home',
  买家分层: 'Stratification',
  买家人群: 'Buyer Group',
  买家属性: 'Buyer Attribute',
  营销策略: 'Marketing Strategy',
  买家总数: 'Total Buyers',
  'MMax可见范围内总买家数，包括已平台授权的店铺买家及导入的买家':
    'The total number of buyers within the visible scope of MMax, including buyers in stores authorized by the platform and buyers imported',
  导入记录: 'Import Record',
  '导入中，请在导入记录中查看进度': 'Importing, please check the progress in the Import Record',
  '导入失败，请稍后再试': 'Import failed, please try again later',
  批量导入: 'Batch Import',
  '更多分析数据，敬请期待～': 'Stay tuned for more analysis data~',
  消息发送总量: 'Total messages',
  可触达买家总数: 'Total reachable buyers',
  活跃买家数: 'Active buyers',
  '获取x失败，请稍后再试': 'Failed to get {{x}}, please try again later',
  共x项: '{{x}} items',
  下载链接: 'Download Link',
  正在导入中: 'Importing',
  '共x条买家记录，本次成功导入y个买家，z条导入异常。':
    'Total buyers {{x}}, sucessessfully imported {{y}}, failed {{z}}',
  下载导入异常记录: 'Download failed record',
  买家来源: 'Buyer Source',
  '若您想导入平台买家，请导入已授权MMax的店铺买家，否则将导入失败。':
    'If you want to import Platform Buyers, please import buyers in stores that have authorized MMax, otherwise it will fail.',
  平台买家: 'Platform Buyer',
  'WhatsApp 买家': 'WhatsApp Buyer',
  请根据模版内容填写买家信息并在下方上传:
    'Please fill in the buyer information according to the template and upload it below',
  批量导入买家模版: 'Batch import buyer templates',
  '下载失败，请稍后再试': 'Download failed, please try again later',
  请先选择买家来源: 'Please select buyer source first',
  下载模版: 'Download template',
  上传文件: 'Upload file',
  请上传文件: 'Please upload file',
  点击或将文件拖拽到这里上传: 'Click or drag files here to upload',
  '支持文件格式：x': 'Support file formats: {{x}}',
  批量导入买家: 'Batch import buyers',
  确认导入: 'Confirm import',
  '导入前，买家属性须先在系统中创建。':
    'Buyer Attribute must be created in the system before importing.',
  点击查看: 'Click to view',
  x说明文档: ' {{x}} Instruction',
  x列表: '{{x}} List',
  输入x搜索: 'Enter {{x}} to search',
  保存成功: 'Saved successfully',
  '保存失败，请稍后再试': 'Failed to save, please try again later',
  '属性值已达上限，现不支持创建':
    'The number of attributes has reached the upper limit, and it is not supported to create',
  新建x: 'Create {{x}}',
  自定义属性: 'Custom attribute',
  请输入x: 'Please enter {{x}}',
  '请不要以“official”开头命名自定义买家属性':
    'Please do not name buyer attributes starting with "official"',
  当前x已存在: 'Currently {{x}} exists',
  属性名称: 'Attribute Name',
  属性说明: 'Attribute Description',
  '可设置难以穷尽的买家属性，例如. 爱好': 'Inexhaustible buyer attributes can be set, eg. hobbies',
  '买家属性设置为数字类型后，可进行数字区间的筛选；例如年龄':
    'After the buyer attribute is set to number type, the number range can be filtered; for example, age',
  '用于圈选不同指标的日期区间，比如筛选最近3天、2022.07.01～2022.07.07':
    'It is used to select the date range of different indicators, such as filtering the last 3 days, 2022.07.01～2022.07.07',
  '可设置易穷尽的买家属性，可在筛选客户时快速选择，例如买家等级':
    'Exhaustive buyer attributes can be set, which can be quickly selected when filtering customers, such as buyer level',
  属性值类型: 'Attribute Type',
  请选择x: 'Please select {{x}}',
  '该类型的属性值已达x个上限，现不支持创建':
    'The attribute of this type has reached the upper limit of {{x}}, and it is not supported to create',
  属性值: 'Attribute value',
  请添加x: 'Please add {{x}}',
  完成: 'Complete',
  取消: 'Cancel',
  添加: 'Add',
  继续添加: 'Continue to add',
  编辑x: 'Edit {{x}}',
  文本: 'text',
  数字: 'number',
  日期: 'date',
  枚举值: 'enum',
  布尔值: 'boolean',
  限定商品: 'Product limited',
  创建时间: 'Creation time',
  操作: 'Action',
  官方买家属性不可编辑: 'Official buyer attributes are not editable',
  编辑: 'Edit',
  删除: 'Delete',
  删除成功: 'Successfully deleted',
  '删除失败，请稍后再试': 'Failed to delete, please try again later',
  保存: 'Save',
  官方: 'official',
  无买家属性规则设置: 'No buyer attribute rule settings',
  可触达买家数: 'Reachable Buyers',
  '营销策略“x”正在使用该人群，不可编辑':
    'Marketing policy {{x}} is using this category and cannot be edited',
  官方买家人群不可编辑: 'The official buyer category cannot be edited',
  官方买家人群不可删除: 'The official buyer category cannot be deleted',
  确定删除该买家人群吗: 'Are you sure you want to delete this buyer category',
  '“x”正在使用该买家人群，请先将营销策略终止后再删除':
    '“{{x}}” is using this buyer category, please terminate the marketing policy before deleting',
  我知道了: 'I got it',
  买家人群名称: 'Buyer category name',
  买家人群说明: 'Buyer category description',
  规则设置: 'Rule settings',
  '第一步：选择用户属性，设置属性值；':
    '<0>Step 1:</0> Select the buyer attribute and set the attribute value;',
  '第二步：选择的属性规则展示在这里，确定规则后，点击保存即可新建人群成功':
    '<0>Step 2:</0> The selected attribute rules are displayed here. After confirming the rules, click Save and create a new category successfully.',
  买家属性规则: 'Attribute Rule',
  点击左侧标签加入过滤规则: 'Click on the left tab to add filter rules',
  选中规则: 'Select rule',
  符合以上: 'Meet the above',
  请选择规则类型: 'Please select a rule type',
  任一条件: 'either condition',
  全部条件: 'all conditions',
  排除规则: 'Exclusion rules',
  '预计触达买家数：x': 'Estimated reachable buyer: <0>{{x}}</0>',
  买家数量: 'Buy Number',
  所属规则: 'Rule',
  限定条件: 'Restriction',
  等于: 'equal',
  大于等于: 'bigger than or equal to',
  小于等于: 'less than or equal to',
  介于: 'between',
  开始数字不可大于等于结束数字: 'The start number cannot be bigger than or equal to the end number',
  请选择范围: 'Please select a range',
  是: 'Yes',
  否: 'No',
  请选择天数: 'Please select the number of days',
  日期区间: 'Date range',
  最近x天: 'Last <0>{{x}}</0> days',
  x天前: 'Before <0>{{x}}</0> days',
  x天: '{{x}} days',
  下发数据: 'Send data',
  营销模版: 'Marketing Template',
  '营销策略已创建，请点击按钮启用':
    'Marketing policy has been created, please click the button to activate',
  立即创建: 'Create now',
  询单促转化: 'Enquiry follow-up',
  复购周期唤醒: 'Repurchase Notification',
  客户流失挽回: 'Customer Retrieval',
  热卖推荐: 'Hot sell',
  自定义营销: 'Custom Marketing',
  x万: '{{x}} million',
  启用中: 'On',
  未启用: 'Off',
  '结束时间不可小于当前时间，请检查营销时机':
    'End time cannot be earlier than current time, please check marketing time',
  '关闭后营销策略将自动停止确认关闭吗？':
    'Marketing policy will automatically terminated after closing, confirm to close?',
  关闭策略: 'Close policy',
  点击按钮后开启营销策略: 'Click the button to start marketing policy',
  每天: 'Everyday',
  每周days: 'Everyweek {{days}}',
  'x start～end 执行': '<0>{{x}}</0> <1>{{start}}～{{end}} execution</1>', // 如：每周一、二、三 09:00～19:00 执行 | 每天 09:00～19:00 执行
  每月days: 'Everymonth {{days}}',
  总下发买家数: 'Total buyers',
  总下发已读数: 'Total readings',
  总下发回复数: 'Total replies',
  正在升级中: 'Upgrading',
  查看数据: 'View data',
  '日度下发买家数（近7天）': 'Buyers daily (last 7 days)',
  策略名称: 'Strategy Name',
  营销渠道: 'Marketing Channel',
  '敬请期待...': 'Stay tuned...',
  店铺: 'Store',
  指定人群: 'Designated group',
  请先选择营销渠道: 'Please select a marketing channel first',
  选择人群: 'Select group',
  '已选人群：x': 'Selected group: {{x}}',
  修改人群: 'Modify group',
  需要选择一个人群: 'Need to choose a group',
  请搜索买家人群名称: 'Please search for buyer group name',
  '符合任一限定规则：': 'Meets any of the rules:',
  '符合全部限定规则：': 'Meets all rules:',
  '过滤以下规则：': 'Filter the following rules:',
  营销时机: 'Marketing time',
  时区: 'Time zone',
  东x区时间: 'East {{x}} time zone',
  西x区时间: 'West {{x}} zone time',
  指定时间: 'Designated time',
  每周循环: 'Weekly cycle',
  每月循环: 'Monthly cycle',
  结束时间不可小于当前时间: 'End time cannot be earlier than current time',
  请选择每周时间: 'Please select a time of week',
  请选择每月时间: 'Please select a time of month',
  每周x每日y: '<a>Week</a><x></x><b>Daily</b><y></y>',
  每月x每日y: '<a>Date</a><x></x><b>Daily</b><y></y>',
  营销内容: 'Marketing Content',
  营销内容不可超出x个字: 'Marketing content cannot exceed {{x}} words',
  存在未上传成功图片: 'There are pictures that have not been uploaded successfully',
  注意事项: 'Attention',
  最多上传x张图片: 'Upload at most {{x}} images',
  '下发效果示意（内容以实际配置为准）': 'Illustration (content is subject to actual configuration)',
  超出图片限制xM大小: 'Exceeded image limit {{x}}M size',
  上传图片: 'Upload image',
  图片预览: 'Image preview',
  重复营销设置: 'Repeat Marketing Settings',
  永不重复: 'Never repeat',
  '在本营销策略中，只对买家发送一次营销内容，不会对买家重复下发':
    'In this marketing policy, the marketing content is only sent to buyers once, and will not be sent repeatedly to buyers',
  重复营销过滤: 'Repeat Marketing Filter',
  近x天被当前渠道已经营销过的买家:
    '<a>Buyers who have been marketed by the current channel in the last </a><x></x><b>days</b>',
  营销设置: 'Marketing Settings',
  基本设置: 'Basic settings',
  启用中的策略不能编辑: 'Active policies cannot be edited',
  '买家人群已不存在，请重新选择': 'The buyer category no longer exists, please select again',
  登录: 'Log in',
  欢迎登录MMax: 'Welcome to MMax',
  请通过乐聊超级管理员账号登录MMax:
    'Please log in to MMax through the CHaT++ super administrator account',
  '手机号/邮箱/账号': 'Mobile number/email/account',
  密码: 'Password',
  忘记密码: 'Forgot password',
  登录中: 'logging in',
  登录成功: 'Login successfully',
  '账号错误，请通过乐聊超级管理员账号登录':
    'The account is wrong, please log in with the CHaT++ super administrator account',
  '账号未授权，请联系MMax工作人员': 'The account is not authorized, please contact MMax staff',
  '密码错误，请重新输入': 'Wrong password, please re-enter',
  '服务器开小差了，请稍后再试': 'Server crashed, please try again later',
  请输入账号: 'Please enter account',
  请输入密码: 'Please enter password',
  请用乐聊超级管理员账号登录: 'CHaT++ super administrator account',
  '如忘记密码，请联系乐聊超级管理员':
    'If you forget your password, please contact CHaT++ super administrator',
  编辑买家人群: 'Edit Buyer Category',
  编辑营销策略: 'Edit Marketing Policy',
  下发转化率: 'Release Conversion Rate',
  '付款买家数/下发买家数': 'Number of Paid Buyers/Number of Marketed Buyers',
  '买家属性值为是/否，例如买家是否转化':
    'Buyer attribute value is Yes/No, e.g. The buyer convert Yes/No',
  '导入失败，请上传x格式文件': 'Failed to import, please upload the file in {{x}} format',
  '创建该买家属性后，属性值类型将无法变更':
    'After the buyer attribute is created, the attribute type cannot be changed',
  配置与管理: 'Configuration',
  话术模板管理: 'Message Template Management',
  账单管理: 'Payment Management',
  消费点券: 'Coin consumed',
  近7天消费点券: 'Coin consumed in 7 days',
  总会话数: 'Total conversation',
  商家发起会话数: 'Conversation initiated by seller',
  买家发起会话数: 'Conversation initiated by buyer',
  查看消费明细: 'View details',
  买家手机号: 'Buyer phone number',
  买家昵称: 'Buyer nickname',
  消费时间: 'Time',
  会话发起方: 'Conversation initiator',
  商家: 'Seller',
  买家: 'Buyer',
  话术模板名称: 'Template name',
  模板状态: 'Template status',
  待审核: 'pending',
  已审核: 'approved',
  未通过: 'rejected',
  已禁用: 'disabled',
  语言: 'Language',
  最后编辑时间: 'Last edit time',
  变量名称: 'Variable name',
  变量说明: 'Variable description',
  昨天: 'Yesterday',
  近x天: 'Last {{x}} days',
  会话消费明细: 'Consumption details',
  下发WABA: 'WABA',
  已消耗点券数: 'Coins consumed',
  明细列表: 'List',
  当前账户点券余额: 'Current coin balance',
  账单: 'Bill',
  WhatsApp会话消费记录: 'WhatsApp conversation consumption records',
  营销额度: 'Marketing limit',
  设置营销额度: 'Set marketing limit',
  点券数: 'Coin',
  '当前点券数较少，当消耗完后策略将停止营销':
    'The current number of coins is low, and the strategy will stop sending when coins are consumed',
  '营销额度为营销策略最多消耗的点券数，额度为0时营销策略自动停止':
    'The marketing limit is the maximum number of coins that can be consumed by the marketing strategy, and the marketing strategy will stop automatically when the limit is 0',
  手机号: 'Phone number',
  模板: 'Template',
  话术模板: 'Message template',
  请上传话术中的x: 'Please upload the {{x}} in the script',
  uploadImageTips: 'To ensure browsing effect, please select 16:9 horizontal screen image',
  '支持扩展名：x': 'Support extension: {{x}}',
  请选择话术中的优惠券码: 'Please select the voucher code in the script',
  请先选择店铺: 'Please select the store first',
  请输入话术中的x: 'Please enter {{x}} in the script',
  优惠券: 'Voucher',
  按钮文本: 'Button text',
  操作类型: 'Operation type',
  拨打电话: 'Call',
  跳转链接: 'Visit website',
  '国家/地区': 'Country/Region',
  电话号码: 'Phone number',
  网址类型: 'Website type',
  静态: 'Static',
  动态: 'Dynamic',
  网址: 'Website',
  无: 'None',
  快速回复: 'Quick reply',
  行动号召: 'Call to action ',
  最多设置x个按钮: 'Set up to {{x}} buttons',
  添加按钮: 'Add button',
  按钮: 'Button',
  '创建按钮，以便买家回复您的消息或采取操作':
    'Create buttons for buyers to respond to your messages or take action',
  渠道: 'Channel',
  正文: 'Text',
  正文不可超出x个字: 'The text should not exceed x words',
  请用您所选择的语言输入消息文本: 'Please enter the message in the language of your choice',
  页脚: 'Footer',
  '应WhatsAspp官方要求，首次触达买家需要获取opt-in,该信息在话术页脚固定展示，不可修改':
    'According to WhatsAspp official requirements, the first time to reach buyers need to get opt-in, which is displayed in the footer of the script and cannot be modified',
  素材: 'Material',
  请选择素材类型: 'Please select material type',
  请上传示例: 'Please upload an example',
  页眉: 'Header',
  添加页眉或为页眉选择要使用的素材类型:
    'Add a header or select a material type to use for the header',
  图片: 'Picture',
  视频: 'Video',
  文件: 'File',
  支持x: 'Support {{x}}',
  最大x: 'Maximum {{x}}', // 如：最大20M
  上传示例: 'Upload examples',
  模板名称: 'Template name',
  '模板名称只能包含小写字母、数字和下划线':
    'Template names can only contain lowercase letters, numbers and underscores',
  示例内容: 'Example',
  请输入示例内容: 'Please enter the sample content',
  变量: 'Variable',
  上传x: 'Upload {{x}}',
  pdf第一页缩略图: 'Pdf first page thumbnail',
  文件名称: 'File name',
  输入后话术预览展示在这里: 'The preview of the script after input is shown here',
  预览: 'Preview',
  表情: 'Expression',
  加粗: 'Bold',
  倾斜: 'Italic',
  删除线: 'Strikethrough',
  待审核的模板不可编辑: 'Templates to be reviewed are not editable',
  已审核的话术模板每24小时仅可编辑一次:
    'Reviewed script templates can only be edited once every 24 hours',
  '“x”已成功提交': '“{{x}}” submitted successfully',
  '确定要取消吗？': 'Are you sure you want to cancel?',
  取消后该模板将不被保存: 'The template will not be saved after cancellation',
  继续填写: 'Continue to input',
  确认取消: 'Confirm Cancel',
  选择渠道: 'Choose channel',
  设置话术模板内容: 'Set the content of template',
  templateTips:
    'Script templates will be officially reviewed by WhatsApp, please clear the <x>WhatsApp Message Template Quality Score</x> in advance and follow the <y>WhatsApp Business Policy</y>.',
  下一步: 'Next',
  x不限: '{{x}} unlimited',
  状态: 'State',
  请输入网址中x的内容: 'Please enter the content of {{x}} in the website',
  '添加变量即可在使用模板时赋值不同的网址。您只能添加一个变量到网址结尾处。':
    'Adding a variable allows you to assign different URLs when using the template. You can only add one variable to the end of the URL.',
  详细了解: 'Learn more',
  已有的语言: 'Existing languages',
  '话术模板中已有的名称，可点击直接选择':
    'You can click to directly select the name that is already in the script template',
  '点击查看变量说明文档，请遵守WhatsApp Business政策':
    '<x>Click to view</x> variable description documentation, please follow <y>WhatsApp Business Policy</y>',
  此名称在该语言下已存在: 'This name already exists in this language',
  转化金额: 'Conversion amount',
  ...newEn,
};

export default en;
