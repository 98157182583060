import { Authorization, RouterQuery, ErrorBoundary } from '@leyan/ddone';
import { ConfigProvider } from 'antd';
import { GlobalProvider, useGlobalValue } from 'components/GlobalContext';
import { HashRouter } from 'react-router-dom';
import { authorize } from 'services';
import { systemLanguageLocaleMap } from 'i18n/systemLanguage';
import AuthRoutes from './AuthRoutes';

function App() {
  return (
    <ErrorBoundary>
      <GlobalProvider>
        <GlobalProviderContent />
      </GlobalProvider>
    </ErrorBoundary>
  );
}

function GlobalProviderContent() {
  const { systemLanguage } = useGlobalValue();

  return (
    <ConfigProvider locale={systemLanguageLocaleMap[systemLanguage]}>
      <HashRouter>
        <RouterQuery>
          <Authorization cacheKey="@veterinary/token" onAuthorize={authorize}>
            <AuthRoutes />
          </Authorization>
        </RouterQuery>
      </HashRouter>
    </ConfigProvider>
  );
}

export default App;
