import cn from './cn';

const whatsApp: Record<Template.WhatsAppLanguage, string> = {
  zh_CN: 'Chinese (CHN)',
  en: 'English',
  fil: 'Filipino',
  id: 'Indonesian',
  ms: 'Malay',
  pt_BR: 'Portuguese (BR)',
  es_MX: 'Spanish (MEX)',
  th: 'Thai',
  vi: 'Vietnamese',
};

const en: typeof cn = {
  whatsApp,
};

export default en;
